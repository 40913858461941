import {makeStyles, useTheme, Link, Grid} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import Logo from 'assets/logo black 1.png';
import {Social} from 'components/AppBar/AppBar';
import {useContext} from 'react';
import {ProfileContext} from 'context/ProfileContext';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		color: 'black',
		background: '#F9F9F9',

		'& a': {
			fontWeight: 400,
			fontSize: 16,
			color: '#7D8494',
			marginBottom: 8,
			textDecoration: 'none'
		},
		[theme.breakpoints.down('sm')]: {
			backgroundColor: '#F9F9F9'
		}
	},
	leftFooter: {
		position: 'relative',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		'& .MuiGrid-container': {
			position: 'relative'
		}
	},
	leftImage: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	infoContainer: {
		marginLeft: 150,
		marginTop: 40,
		[theme.breakpoints.down('sm')]: {margin: '30px 0 10px 20px'}
	},
	logo: {
		width: 90,
		[theme.breakpoints.down('sm')]: {marginBottom: 20}
	},
	contacts: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		color: '#7D8494',
		lineHeight: 2,
		marginBottom: 20
	},
	phoneNumber: {
		width: 'fit-content'
	},
	links: {
		paddingLeft: 60,
		[theme.breakpoints.down('sm')]: {
			padding: '0px 60px 30px 0px'
		}
	},
	social: {
		display: 'flex',
		flexWrap: 'wrap',
		width: 180,
		marginTop: 20,
		[theme.breakpoints.down('sm')]: {paddingBottom: 50}
	},
	p: {
		fontSize: 14,
		fontWeight: 'normal',
		bottom: 12,
		paddingTop: 15,
		position: 'absolute',
		left: 59,
		color: 'white',
		[theme.breakpoints.down('sm')]: {left: 20}
	},
	rightFooter: {
		zIndex: 1,
		'& img': {
			height: '100%',
			marginLeft: -45
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	}
}));

const ContactUs = () => {
	const classes = useStyles();
	const theme = useTheme();

	const {state: profileState} = useContext(ProfileContext);

	return (
		<div className={classes.container}>
			<Grid container className={classes.infoContainer}>
				<Grid item xs={12} sm={6} lg={4}>
					<NavLink to="/search">
						<img src={Logo} alt={'Logo'} className={classes.logo} />
					</NavLink>
					<p
						style={{
							marginTop: 20,
							paddingBottom: 20,
							fontWeight: 400,
							fontSize: 16,
							color: '#343548'
						}}>
						Lorem ipsum is simply <br /> dummy text of the printing <br /> and typesetting
						industry.
					</p>
				</Grid>
				<Grid item xs={12} sm={6} lg={4}>
					<span
						style={{
							fontSize: 18,
							fontWeight: 500
						}}>
						Contact Information
					</span>
					<div className={classes.contacts}>
						<span>Nalbandyan street, 23, Yerevan, Armenia </span>
						<span>hot_tours@inbox.ru</span>
						<Link
							className={classes.phoneNumber}
							href="tel:+1234567890"
							underline="none"
							color="inherit">
							(+37460) 40-44-40
						</Link>
						<Link
							className={classes.phoneNumber}
							href="tel:+1234567890"
							underline="none"
							color="inherit">
							(+37410) 58-96-96
						</Link>
					</div>
				</Grid>

				<Grid item xs={12} sm={6} lg={4}>
					<span
						style={{
							fontSize: 18,
							fontWeight: 500
						}}>
						Follow us
					</span>
					<div className={classes.social}>
						<Social />
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default ContactUs;
