const ADMIN = 'Admin';
const AGENT = 'user';
const VIEWER = 'Viewer';
const SUPER_ADMIN = 'Super Admin';
const USER_REPORTS = 'Reports';
const ADMIN_TICKETS = 'Tickets';
const ADMIN_MANAGER = 'Manager';

const roles = {
	ADMIN,
	AGENT,
	VIEWER,
	SUPER_ADMIN,
	USER_REPORTS,
	ADMIN_TICKETS,
	ADMIN_MANAGER
};

export default roles;
