import SEARCH from './search.service';
import PROFILE from './profile.service';
import AGENTS from './agents.service';
import FLIGHTS from './flights.service';
import HISTORY from './history.service';
import RESERVATIONS from './reservations.service';
import SETTINGS from './settings.service';
import REPORTS from './reports.service';

const PRIVATE_SERVICES = {
	SEARCH,
	PROFILE,
	AGENTS,
	FLIGHTS,
	HISTORY,
	RESERVATIONS,
	SETTINGS,
	REPORTS
};

export default PRIVATE_SERVICES;
