import React from 'react';
import type {RouteProps} from 'react-router-dom';
import ROLES from 'constants/role.contstants';

interface PrivateRouteProps extends RouteProps {
	name: string;
	role: string[];
	icon?: React.ReactElement;
	disableForRoles?: string[];
	navBar?: boolean;
}

export const privateRoutes: Array<PrivateRouteProps> = [
	{
		path: '/search',
		name: 'Search',
		component: React.lazy(() => import('./pages/privatePages/Search copy')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.USER_REPORTS,
			ROLES.ADMIN_MANAGER
		],

		navBar: true
	},
	// {
	// 	path: '/search',
	// 	name: 'Search',
	// 	component: React.lazy(() => import('./pages/privatePages/Search')),
	// 	role: [ROLES.ADMIN, ROLES.AGENT],
	// 	navBar: true
	// },
	{
		path: '/checkout',
		name: 'Checkout',
		component: React.lazy(() => import('./pages/privatePages/Checkout')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_MANAGER
		]
	},
	{
		path: '/reservations',
		name: 'Reservations',
		component: React.lazy(() => import('./pages/privatePages/Reservations')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_MANAGER
		],

		navBar: true
	},
	{
		path: '/flights',
		name: 'Flights',
		component: React.lazy(() => import('./pages/privatePages/Flights')),
		exact: true,
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_TICKETS, ROLES.ADMIN_MANAGER],
		navBar: true
	},
	{
		path: '/flights/:id',
		name: 'Flight Classes',
		component: React.lazy(() => import('./pages/privatePages/FlightClasses')),
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_TICKETS, ROLES.ADMIN_MANAGER]
	},
	{
		path: '/agents',
		name: 'Agents',
		component: React.lazy(() => import('./pages/privatePages/Agents')),
		exact: true,
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_TICKETS],
		navBar: true
	},
	{
		path: '/agents/:id',
		name: 'Agent Info',
		component: React.lazy(() => import('./pages/privatePages/AgentBalance')),
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_TICKETS]
	},
	{
		path: '/passengers',
		name: 'Passengers',
		component: React.lazy(() => import('./pages/privatePages/Passengers')),
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_TICKETS],
		navBar: true
	},
	{
		path: '/history',
		name: 'History',
		component: React.lazy(() => import('./pages/privatePages/History')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_MANAGER
		],
		navBar: true
	},

	{
		path: '/reports',
		name: 'Reports',
		component: React.lazy(() => import('./pages/privatePages/Reports')),
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.USER_REPORTS],
		navBar: true
	},
	{
		path: '/passengers',
		name: 'Passengers',
		component: React.lazy(() => import('./pages/privatePages/Passengers')),
		role: [ROLES.ADMIN],
		navBar: true
	},
	{
		path: '/settings',
		name: 'Settings',
		component: React.lazy(() => import('./pages/privatePages/Settings')),
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_TICKETS]
	},
	{
		path: '/profile',
		name: 'Profile',
		component: React.lazy(() => import('./pages/privatePages/Profile')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.USER_REPORTS,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_MANAGER
		]
	},
	{
		path: '/currency',
		name: 'Currency',
		component: React.lazy(() => import('./pages/privatePages/Currency')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.USER_REPORTS,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_MANAGER
		]
	},
	{
		path: '/confirm',
		name: 'Confirm Ticket Status',
		component: React.lazy(() => import('./pages/privatePages/Checkout/ConfirmTicketStatus')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_MANAGER
		]
	},
	{
		path: '/info/:pnr',
		name: 'View Information',
		component: React.lazy(() => import('./pages/privatePages/Reservations/ViewInfo')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_MANAGER
		]
	}
];

export const publicRoutes: Array<RouteProps> = [
	{
		path: '/',
		component: React.lazy(() => import('./pages/publicPages/Login')),
		exact: true
	},
	{
		path: '/login',
		component: React.lazy(() => import('./pages/publicPages/Login')),
		exact: true
	},
	{
		path: '/register',
		component: React.lazy(() => import('./pages/publicPages/Register')),
		exact: true
	},
	{
		path: '/reset',
		component: React.lazy(() => import('./pages/publicPages/ResetPassword')),
		exact: true
	},

	{
		path: '/resetVerification',
		component: React.lazy(() => import('./pages/publicPages/ResetPassword/CreateNewPassword')),
		exact: true
	},
	{
		path: '/verification',
		component: React.lazy(() => import('./pages/publicPages/RegisterVerification')),
		exact: true
	}
];
