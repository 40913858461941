import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

export interface SearchProps {
	travelType?: string;
	passengerTypeAdults?: number;
	passengerTypeChild?: number;
	passengerTypeInfant?: number;
	departureFrom?: string;
	destinationTo?: string;
	departureDate?: string;
	returnDate?: string;
}

const searchFlight = async (data: SearchProps) => {
	const response = await axiosClient.post(api.PRIVATE.SEARCH_FLIGHT, data);
	return response.data;
};

export interface PreOrderFlightProps {
	departureFlightId: string;
	departureClassId: string;
	returnFlightId?: string;
	returnClassId?: string;
	travelType: string;
	passengerTypeAdults: number;
	passengerTypeChild: number;
	passengerTypeInfant: number;
}

const preOrderFlight = async (data: PreOrderFlightProps) => {
	const response = await axiosClient.post(api.PRIVATE.PRE_ORDER, data);
	return response.data;
};

const createOrder = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_ORDER, data);
	return response.data;
};

const service = {
	searchFlight,
	preOrderFlight,
	createOrder
};

export default service;
