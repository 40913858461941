import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

const getOrders = async (data: {startDate: string; endDate: string; agentId?: string}) => {
	const response = await axiosClient.post(api.PRIVATE.GET_ORDERS, data);
	return response.data;
};

const getOrderByPnr = async (pnr: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_ORDER_BY_PNR + pnr);
	return response.data;
};

/**
 * Update
 */

const updateOrder = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.UPDATE_ORDER + id, data);
	return response.data;
};

const cancelPreOrder = async (pnr: string) => {
	if (!!localStorage.getItem('reservationCopy')) localStorage.removeItem('reservationCopy');
	const response = await axiosClient.post(api.PRIVATE.CANCEL_PRE_ORDER + pnr);
	return response.data;
};

const cancelBooking = async (pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CANCEL_BOOKING + pnr);
	return response.data;
};

interface BookingToTicketingParams {
	paymentType: string;
	partialPayment?: string | undefined;
	partialCurrency?: string | undefined;
}

const bookingToTicketing = async (data: BookingToTicketingParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.BOOKING_TO_TICKETING + pnr, data);
	return response.data;
};

interface RefundTicketParams {
	placesDepartureClass: string;
	placesReturnClass?: string;
	commissionInfo: string;
}

const refundTicket = async (data: RefundTicketParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.REFUND_TICKET + pnr, data);
	return response.data;
};

interface SplitTicketParams {
	ticketNumber: string;
}

const splitTicket = async (data: SplitTicketParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.SPLIT_TICKET + pnr, data);
	return response.data;
};

const changePrice = async (data: any, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CHANGE_PRICE + pnr, data);
	return response.data;
};

const service = {
	getOrders,
	getOrderByPnr,
	updateOrder,
	changePrice,
	cancelPreOrder,
	cancelBooking,
	bookingToTicketing,
	refundTicket,
	splitTicket
};

export default service;
