import React, {useState, useContext} from 'react';
import {
	AppBar,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import ListItemLink from 'components/ListItemLink';
import {ProfileContext} from 'context/ProfileContext';
import Logo from 'assets/logo.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {privateRoutes} from 'router';
import {AuthContext} from 'context/AuthContext';
import {NavLink} from 'react-router-dom';
import ROLES from 'constants/role.contstants';
import AppBarComponent from 'components/AppBar';
import {currencyFormatterDecimal} from 'helpers/currency.helper';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroIcon from '@material-ui/icons/Euro';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '8px 24px',
		background: '#FFFFFF',
		boxShadow: '0 2px 2px -2px grey',
		marginBottom: 1
	},
	leftBlock: {
		display: 'flex',
		alignItems: 'center',
		// paddingLeft: 50,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			justifyContent: 'space-between',
			paddingLeft: 0
		}
	},
	logo: {
		width: 90,
		marginLeft: 25,
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px!important'
		}
	},
	navBar: {
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			minWidth: theme.breakpoints.values.xs
		}
	},
	menuButton: {
		color: theme.palette.primary.main,
		width: 'fit-content',
		alignSelf: 'center'
	},
	menuItem: {
		paddingLeft: 28,
		paddingRight: 28,
		'& .MuiTypography-root': {
			fontWeight: 600,
			fontSize: 14
		},
		'& .active': {
			color: theme.palette.secondary.main
		},
		'& .active .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		}
	},
	balance: {
		paddingLeft: 16,
		paddingRight: 28,
		'& .MuiTypography-root': {
			fontWeight: 600,
			fontSize: 14
		},
		'& .active': {
			color: theme.palette.secondary.main
		},
		'& .active .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		}
	},
	currencyBtn: {
		'& .MuiButton-label': {
			justifyContent: 'flex-start',
			paddingLeft: 5
		}
	},
	closeButton: {
		display: 'flex',
		justifyContent: 'flex-start',
		paddingLeft: 25
	}
}));

const NavBar = () => {
	const classes = useStyles();
	const theme = useTheme();
	const {dispatch: authDispatch} = useContext(AuthContext);
	const {state: profileState} = useContext(ProfileContext);

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const ROLE =
		profileState.adminGroups && profileState.adminGroups !== '...'
			? profileState.adminGroups
			: profileState.role;

	const filteredRoutes = privateRoutes.filter(el => el.navBar && el.role.includes(ROLE));

	const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		setIsDrawerOpen(state);
	};

	const handleLogout = () => {
		authDispatch({
			type: 'LOGOUT'
		});
	};

	const MenuList = () => (
		<List className={classes.navBar}>
			{filteredRoutes.map((el: any) => {
				let listName = el.name;
				return (
					<ListItemLink
						onClick={toggleDrawer(false)}
						key={el.path}
						to={el.path.indexOf('/:') !== -1 ? el.path.substr(0, el.path.indexOf('/:')) : el.path}
						primary={listName}
						icon={el.icon}
					/>
				);
			})}
			{useMediaQuery(theme.breakpoints.down('md')) ? (
				<>
					<Divider />
					{(ROLE === ROLES.ADMIN ||
						ROLE === ROLES.SUPER_ADMIN ||
						ROLE === ROLES.VIEWER ||
						ROLE === ROLES.ADMIN_TICKETS) && (
						<ListItemLink to="/settings" primary="Settings" onClick={toggleDrawer(false)} />
					)}
					<ListItemLink to="/profile" primary="Profile" onClick={toggleDrawer(false)} />

					<div className={classes.balance}>{currencyFormatterDecimal(profileState.balance)}</div>
					<Button
						className={classes.currencyBtn}
						component={RouterLink}
						to={'/currency'}
						color="inherit">
						<div
							style={{
								display: 'flex',
								// flexDirection: useMediaQuery(theme.breakpoints.down('md')) ? 'column' : 'row',
								justifyContent: 'center',
								flexDirection: 'column',
								alignItems: 'center',
								width: 'fit-content',
								height: '100%',
								fontSize: 16
							}}
							color="inherit">
							<div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
								<AttachMoneyIcon fontSize="small" />
								{profileState.usdRate}
							</div>
							<div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
								<EuroIcon fontSize="small" style={{marginRight: 3}} />
								{profileState.eurRate}
							</div>
						</div>
					</Button>
					<li>
						<ListItem
							button
							onClick={handleLogout}
							className={classes.menuItem}
							style={{paddingLeft: 17}}>
							<ListItemText primary="Log out" />
						</ListItem>
					</li>
				</>
			) : null}
		</List>
	);

	return (
		<div className={classes.container}>
			<div className={classes.leftBlock}>
				<NavLink to="/">
					<img src={Logo} alt={'Logo'} className={classes.logo} />
				</NavLink>
				{useMediaQuery(theme.breakpoints.up('lg')) ? (
					<MenuList />
				) : (
					<>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawer(true)}
							edge="end"
							className={classes.menuButton}
							size="medium">
							<MenuIcon />
						</IconButton>
						<Drawer
							anchor="right"
							variant="temporary"
							open={isDrawerOpen}
							onClose={toggleDrawer(false)}>
							<IconButton
								color="inherit"
								aria-label="close drawer"
								onClick={toggleDrawer(false)}
								edge="start"
								className={classes.closeButton}
								size="medium">
								<CloseIcon style={{color: 'black'}} />
							</IconButton>
							<MenuList />
						</Drawer>
					</>
				)}
			</div>

			<div>
				<AppBarComponent />
			</div>
		</div>
	);
};

export default NavBar;
