import {Container, Typography, makeStyles} from '@material-ui/core';
import packageJson from '../../../package.json';

function Copyright() {
	const classes = useStyles();
	return (
		<div
			className={classes.footerBlock}
			style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
			<div style={{fontSize: 14}}>Flights Management System v{packageJson.version}</div>

			<div style={{fontSize: 14}}>
				All Rights Reserved 2018 - 2022{' '}
				<a href="http://www.smartsoft.am" target="_blank" style={{color: 'black'}}>
					SMARTSOFT LLC
				</a>
			</div>
		</div>
	);
}

const useStyles = makeStyles(theme => ({
	footer: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingRight: theme.spacing(5),
		paddingLeft: theme.spacing(5),
		marginTop: 'auto',
		color: '#343548',
		display: 'flex',
		marginRight: '0!important',
		marginLeft: '0!important'
	},
	footerBlock: {
		[theme.breakpoints.down('xs')]: {
			display: 'block!important'
		}
	}
}));

const Footer = () => {
	const classes = useStyles();
	return (
		<footer className={classes.footer}>
			{/* <Container> */}
			<Copyright />
			{/* </Container> */}
		</footer>
	);
};

export default Footer;
