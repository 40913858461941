const APPROVED_USER_EMAIL = '/approved.php';
const SEND_TICKET_EMAIL = '/ticketSend.php';
const SEND_BOOKING_TICKET_EMAIL = '/bookingTicketSend.php';
const SEND_INVOICE_EMAIL = '/invoiceSend.php';
const SEND_RECEIPT_EMAIL = '/receiptsSend.php';
const REGISTER_CONFIRM_EMAIL = '/index.php';
const RESET_PASSWORD_EMAIL = '/forgotPasswordVerify.php';
const SEND_BOOKING_CANCEL_EMAIL = '/bookingCancelSend.php';
const SEND_BOOKING_ADD_EMAIL = '/bookingAddSend.php';
const SEND_REFUND_TICKET_EMAIL = '/ticketRefundSend.php';
const SEND_ISSUE_TICKET_EMAIL = '/ticketIssueSend.php';

const EMAIL_API = {
	APPROVED_USER_EMAIL,
	SEND_TICKET_EMAIL,
	SEND_BOOKING_TICKET_EMAIL,
	SEND_INVOICE_EMAIL,
	SEND_RECEIPT_EMAIL,
	REGISTER_CONFIRM_EMAIL,
	RESET_PASSWORD_EMAIL,
	SEND_BOOKING_CANCEL_EMAIL,
	SEND_BOOKING_ADD_EMAIL,
	SEND_REFUND_TICKET_EMAIL,
	SEND_ISSUE_TICKET_EMAIL
};

export default EMAIL_API;
