import {useMemo, forwardRef, useContext} from 'react';
import {ListItem, ListItemIcon, ListItemText, makeStyles} from '@material-ui/core';
import {NavLink, NavLinkProps} from 'react-router-dom';
import {ProfileContext} from 'context/ProfileContext';

const useStyles = makeStyles(theme => ({
	listIcon: {
		minWidth: 30
	},
	menuItem: {
		'& .MuiTypography-root': {
			fontWeight: 'normal',
			fontSize: 16,
			fontStyle: 'normal'
		},
		'&.active  .MuiTypography-root': {
			color: theme.palette.secondary.main,
			fontWeight: 600
		},
		'&.active .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		}
	}
}));

interface ListItemLinkProps {
	icon?: React.ReactElement;
	primary: React.ReactElement | string;
	to: string;
	onClick?: (val: any) => void;
	disableForRoles?: string[];
}

const ListItemLink = (props: ListItemLinkProps) => {
	const {icon, primary, to, onClick, disableForRoles} = props;
	const classes = useStyles();

	const renderLink = useMemo(
		() =>
			forwardRef<any, Omit<NavLinkProps, 'to'>>((itemProps, ref) => (
				<NavLink to={to} ref={ref} {...itemProps} onClick={onClick} />
			)),
		[to]
	);
	const {state: profileState} = useContext(ProfileContext);

	const ROLE =
		profileState.adminGroups && profileState.adminGroups !== '...'
			? profileState.adminGroups
			: profileState.role;

	const disabled =
		primary === 'Search' ||
		primary === 'Search Copy' ||
		primary === 'Flights' ||
		primary === 'Agents' ||
		primary === 'History' ||
		primary === 'Reports' ||
		primary === 'Passengers' ||
		primary === 'Settings' ||
		primary === 'Profile' ||
		primary === 'Sign up' ||
		primary === 'Forgot your password?' ||
		primary === 'Log in' ||
		primary === 'Reservations' ||
		primary === 'Cancel';

	return (
		<li>
			<ListItem
				button
				component={renderLink}
				className={classes.menuItem}
				disabled={!disabled || disableForRoles?.includes(ROLE)}>
				{icon ? <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
};

export default ListItemLink;
