import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

interface GetAirportsParams {
	flightStatus: string;
}

const getAirports = async (data?: GetAirportsParams) => {
	const response = await axiosClient.get(api.PRIVATE.GET_AIRPORTS, {params: data});
	return response.data;
};

const getAirlines = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_AIRLINES);
	return response.data;
};

const getAircrafts = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_AIRCRAFTS);
	return response.data;
};

const getFlightTemplates = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_FLIGHT_TEMPLATES);
	return response.data;
};

const getFlightTemplateById = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_FLIGHT_TEMPLATES + id);
	return response.data;
};

const getFlightClassTemplates = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_FLIGHT_CLASS_TEMPLATES);
	return response.data;
};

const getFareRules = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_FARE_RULES);
	return response.data;
};

const getBaggageRules = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_BAGGAGE_RULES);
	return response.data;
};

const getCompanySettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_COMPANY_SETTINGS);
	return response.data;
};

const getSMTPSettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_SMTP_SETTINGS);
	return response.data;
};

const getTicketSettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_TICKET_SETTINGS);
	return response.data;
};

const getNotifications = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_NOTIFICATIONS);
	return response.data;
};

const getTimeLimits = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_TIME_LIMITS);
	return response.data;
};

const getCurrencySettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.CURRENCY);
	return response.data;
};

const getCurrencyMarginTypes = async () => {
	const response = await axiosClient.get(api.PRIVATE.CURRENCY_MARGIN_TYPES);
	return response.data;
};

/**
 * CREATE
 */
interface CreateAirportParams {
	country: string;
	city: string;
	airport: string;
	iata: string;
}

const createAirport = async (data: CreateAirportParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_AIRPORTS, data);
	return response.data;
};

interface CreateAirlineParams {
	name: string;
	iata: string;
	icao: string;
	mainPic: File;
}

const createAirline = async (data: CreateAirlineParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_AIRLINES, data);
	return response.data;
};

interface CreateAircraftParams {
	name: string;
	seats: string;
}

const createAircraft = async (data: CreateAircraftParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_AIRCRAFT, data);
	return response.data;
};

interface CreateFlightTemplatesParams {
	templateName: string;
	departureDate: string;
	flightNumber: string;
	arrivalDate: string;
	departureAirport: string;
	arrivalAirport: string;
	airline: string;
	duration: string;
	aircrafts: string;
	bookingTime: string;
	currency: string;
	ticketCode: string;
}

const createFlightTemplates = async (data: CreateFlightTemplatesParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_FLIGHT_TEMPLATES, data);
	return response.data;
};

interface CreateFlightClassTemplatesParams {
	onlyForAdmin: boolean;
	templateName: string;
	class: string;
	seats: number;
	avSeats: number;
	classType: string;
	travelType: string;
	numberOfSeats: number;
	fareAdult: number;
	fareChd: number;
	fareInf: number;
	taxAdult: number;
	taxChd: number;
	cat: number;
	multi: number;
	long: number;
	short: number;
	commAdult: number;
	commChd: number;
}

const createFlightClassTemplates = async (data: CreateFlightClassTemplatesParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_FLIGHT_CLASS_TEMPLATES, data);
	return response.data;
};

const createFareRules = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_FARE_RULES, data);
	return response.data;
};

interface CreateBaggageRulesParams {
	description: string;
	baggageName: string;
}

const createBaggageRules = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_BAGGAGE_RULES, data);
	return response.data;
};

interface CreateCompanySettingsParams {
	companyName: string;
	address: string;
	phone: number;
	email: string;
	logo: File;
}

const createCompanySettings = async (data: CreateCompanySettingsParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_COMPANY_SETTINGS, data);
	return response.data;
};

interface CreateSMTPSettingsParams {
	companyName: string;
	address: string;
	phone: number;
	email: string;
	logo: File;
}

const createSMTPSettings = async (data: CreateSMTPSettingsParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_SMTP_SETTINGS, data);
	return response.data;
};

const createTicketSettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_TICKET_SETTINGS, data);
	return response.data;
};

const createTimeLimits = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_TIME_LIMITS, data);
	return response.data;
};

interface CreateNotificationsParams {
	companyName: string;
	address: string;
	phone: number;
	email: string;
	logo: File;
}

const createNotifications = async (data: CreateNotificationsParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_NOTIFICATIONS, data);
	return response.data;
};
const createCurrencySettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CURRENCY, data);
	return response.data;
};

/**
 * Update
 */
const updateAirport = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_AIRPORT + id, data);
	return response.data;
};

const updateAirline = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_AIRLINE + id, data);
	return response.data;
};

const updateAircraft = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_AIRCRAFT + id, data);
	return response.data;
};

const updateFlightTemplate = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_FLIGHT_TEMPLATE + id, data);
	return response.data;
};

const updateFlightClassTemplate = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_FLIGHT_CLASS_TEMPLATES + id, data);
	return response.data;
};

const updateFareRules = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_FARE_RULES + id, data);
	return response.data;
};

const updateBaggageRules = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_BAGGAGE_RULES + id, data);
	return response.data;
};
const updateCompanySettings = async (data: any) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_COMPANY_SETTINGS, data);
	return response.data;
};

const updateSMTPSettings = async (data: any) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_SMTP_SETTINGS, data);
	return response.data;
};

const updateTicketSettings = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_TICKET_SETTINGS + id, data);
	return response.data;
};

const updateNotifications = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_NOTIFICATIONS + id, data);
	return response.data;
};

const updateCurrencySettings = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.CURRENCY + id, data);
	return response.data;
};

const updateTimeLimits = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_TIME_LIMITS + id, data);
	return response.data;
};

/**
 * Delete
 */
const deleteAirport = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_AIRPORT + id);
	return response.data;
};

const deleteAirline = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_AIRLINE + id);
	return response.data;
};
const deleteAircraft = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_AIRCRAFT + id);
	return response.data;
};

const deleteCurrencySettings = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.CURRENCY + id);
	return response.data;
};

const deleteFlightTemplate = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_FLIGHT_TEMPLATE + id);
	return response.data;
};

const deleteFlightClassTemplates = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_FLIGHT_CLASS_TEMPLATES + id);
	return response.data;
};

const deleteFareRules = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_FARE_RULES + id);
	return response.data;
};

const deleteBaggageRules = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_BAGGAGE_RULES + id);
	return response.data;
};

const deleteNotifications = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_NOTIFICATIONS + id);
	return response.data;
};

const service = {
	getAirports,
	getAirlines,
	getAircrafts,
	getFlightTemplates,
	getFlightTemplateById,
	getFlightClassTemplates,
	getFareRules,
	getBaggageRules,
	getCompanySettings,
	getSMTPSettings,
	getTicketSettings,
	getNotifications,
	getCurrencyMarginTypes,
	getCurrencySettings,
	getTimeLimits,

	createAirport,
	createAirline,
	createAircraft,
	createFlightTemplates,
	createFlightClassTemplates,
	createFareRules,
	createBaggageRules,
	createCompanySettings,
	createSMTPSettings,
	createTicketSettings,
	createCurrencySettings,
	createNotifications,
	createTimeLimits,

	updateAirport,
	updateAirline,
	updateAircraft,
	updateFlightTemplate,
	updateFlightClassTemplate,
	updateFareRules,
	updateBaggageRules,
	updateCompanySettings,
	updateSMTPSettings,
	updateTicketSettings,
	updateCurrencySettings,
	updateNotifications,
	updateTimeLimits,

	deleteAirport,
	deleteAirline,
	deleteAircraft,
	deleteFlightTemplate,
	deleteCurrencySettings,
	deleteFlightClassTemplates,
	deleteFareRules,
	deleteBaggageRules,
	deleteNotifications
};

export default service;
