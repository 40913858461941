import {Redirect, Route, RouteProps} from 'react-router-dom';
import {StateProps} from 'context/AuthContext';
import {useContext} from 'react';
import {ProfileContext} from 'context/ProfileContext';

interface PublicRouteProps extends RouteProps {
	authState: StateProps;
}

const PublicRoute = ({component: Component, authState, ...rest}: PublicRouteProps) => {
	const {state: profileState} = useContext(ProfileContext);
	const ROLE =
		profileState.adminGroups && profileState.adminGroups !== '...'
			? profileState.adminGroups
			: profileState.role;

	return (
		<Route
			{...rest}
			render={props =>
				!authState.isAuthenticated ? (
					Component && <Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: 'search',
							state: {from: props.location}
						}}
					/>
				)
			}
		/>
	);
};

export default PublicRoute;
