import {useState} from 'react';
import {Popover, ButtonProps, Tooltip, IconButton, Button, makeStyles} from '@material-ui/core';
import person from 'assets/icons/person.png';
const useStyles = makeStyles(theme => ({
	popover: {
		'& li': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'baseline'
		}
	},
	btn: {
		textTransform: 'none'
	},
	showMoreIcon: {
		cursor: 'pointer',
		marginLeft: 5,
		color: theme.palette.secondary.main
	}
}));

interface PopoverComponentProps {
	title: string;
	children: any;
	buttonColor?: ButtonProps['color'];
	btnText?: boolean;
	showDotes?: boolean;
}

const PopoverComponent = ({
	title,
	buttonColor = 'inherit',
	btnText = false,
	showDotes = false,
	children
}: PopoverComponentProps) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: any) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<>
			{showDotes ? (
				<>
					<span>{title?.length > 20 ? title.substring(0, 20) : title}</span>
					{title?.length > 20 && (
						<span className={classes.showMoreIcon} onClick={handleClick}>
							...
						</span>
					)}
				</>
			) : btnText ? (
				<Button
					className={classes.btn}
					variant="text"
					color="secondary"
					size="small"
					onClick={handleClick}>
					{title}
				</Button>
			) : (
				<Tooltip title={title}>
					<IconButton color="inherit" onClick={handleClick}>
						<img src={person} alt="person icon" />
					</IconButton>
				</Tooltip>
			)}

			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				className={classes.popover}
				onClick={e => {
					handleClose(e);
					e.stopPropagation();
				}}>
				{children}
			</Popover>
		</>
	);
};

export default PopoverComponent;
